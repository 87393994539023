<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Nota'"
      :modal="true"
      :closable="false"
    >
      <form
        name="AddNoteForm"
        class="p-col-12"
        @submit.prevent="addNote"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <Editor
              name="note"
              v-model="note"
              v-validate="'required'"
              editorStyle="height: 200px"
              v-bind:class="[{ 'p-error': errors.has('note') }, 'form-control']"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                </span>
              </template>
            </Editor>
            <small v-if="errors.has('note')" class="p-error" role="alert">
              Nota é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="addNote"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketService from "../../services/tickets.service";

export default {
  name: "TicketAddNote",
  props: ["show", "showToast", "ticketId"],
  data() {
    return {
      note: null,
    };
  },
  methods: {
    cancel() {
      this.note = null;
      return this.$emit("cancel");
    },
    addNote() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = { note: this.note };
        return ticketService
          .addNote(this.ticketId, bodyParams)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: `Erro ao adicionar nota`,
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("addedNote", {
                status: "error",
                currentStatus: {},
              });
            }
            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Adicionada a Nota`,
                detail: "",
                life: 3000,
              });
            }
            this.note = null;
            return this.$emit("addedNote", {
              status: "ok",
              note: response,
            });
          });
      });
    },
  },
};
</script>
