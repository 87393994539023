<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogMoveTicket" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Importar Tickets'"
      :modal="true"
      :closable="false"
    >
      <form
        name="importTicketsForm"
        class="p-col-12"
        @submit.prevent="moveTicket"
        style="min-height: 15vh"
      >
        <p>
          <b>Ticket {{ ticketSubject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-5 p-md-5 p-mt-4">
            <span class="p-float-label">
              <InputNumber
                name="newTicket"
                :useGrouping="false"
                v-model="getTicketId"
                :min="1"
                v-bind:class="['form-control']"
                @keyup.enter="getTicketById"
              >
              </InputNumber>
              <label for="newTicket">Introduza o Id Ticket a importar</label>
            </span>
          </div>
          <div class="p-field p-col-1 p-md-1 p-mt-4">
            <Button
              icon="pi pi-search"
              class="p-button-rounded"
              @click="getTicketById"
            />
          </div>

          <div class="p-col-5 p-md-5 p-mt-4">
            <span class="p-float-label">
              <InputText
                name="searchTicket"
                :useGrouping="false"
                v-model="searchTicketTerm"
                :min="1"
                v-bind:class="['form-control']"
                @keyup.enter="searchTicket"
              >
              </InputText>
              <label for="searchTicket">Pesquisar Ticket</label>
            </span>
          </div>
          <div class="p-field p-col-1 p-md-1 p-mt-4">
            <Button
              icon="pi pi-search"
              class="p-button-rounded"
              @click="searchTicket"
            />
          </div>

          <div class="p-field p-col-12 p-md-12 p-error" v-if="errorMsg">
            {{ errorMsg }}
          </div>
          <div class="p-col-12" v-if="ticketsList.length > 0">
            <h5>
              Selecione os Tickets a Importar para Dentro do W4M_{{ ticketId }}:
            </h5>
            <div v-for="ticket in ticketsList" :key="ticket.id">
              <div v-if="ticket.id != ticketId">
                <Checkbox
                  v-model="selectedTickets"
                  :binary="false"
                  :value="ticket.id"
                  :id="ticket.id"
                  :inputId="ticket.id"
                  name="ticketsSelected"
                />
                <div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Id</b></div>
                    <div class="p-col-10">W4M_{{ ticket.id }}</div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Estado</b></div>
                    <div class="p-col-10">
                      {{ ticket.status ? ticket.ticket_status.name : null }}
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Cliente</b></div>
                    <div class="p-col-10">
                      {{ getEmailInfo(ticket.client_email) }}
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Assunto</b></div>
                    <div class="p-col-10">{{ ticket.subject }}</div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="ticket.created_at"
                  >
                    <div class="p-col-2"><b>Criado</b></div>
                    <div class="p-col-10">
                      {{
                        ticket.created_by == 0
                          ? "Intranet"
                          : ticket.ticket_created_user.username
                      }}
                      | {{ dateTime(ticket.created_at) }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="ticket.updated_at"
                  >
                    <div class="p-col-2"><b>Atualizado</b></div>
                    <div
                      class="p-col-10"
                      v-if="
                        ticket.updated_at &&
                        ticket.updated_at != ticket.created_at
                      "
                    >
                      {{
                        ticket.updated_by == 0
                          ? "Intranet"
                          : ticket.ticket_updated_user.username
                      }}
                      | {{ dateTime(ticket.created_at) }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="ticket.assigned_to"
                  >
                    <div class="p-col-2"><b>Responsável</b></div>
                    <div class="p-col-10">
                      {{ ticket.ticket_assigned_user.username }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="ticket.priority"
                  >
                    <div class="p-col-2"><b>Prioridade</b></div>
                    <div class="p-col-10">
                      {{ ticket.ticket_priority.name }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="ticket.category"
                  >
                    <div class="p-col-2"><b>Categoria</b></div>
                    <div class="p-col-10">
                      {{ ticket.ticket_category.name }}
                    </div>
                  </div>

                  <div class="p-col-6 p-mt-1">
                    <Button
                      :label="'Ver Ticket a Importar'"
                      icon="pi pi-eye"
                      @click="viewTicket(ticket.id)"
                    />
                  </div>
                  <divider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="selectedTickets.length > 0"
          :label="'Mover'"
          icon="pi pi-check"
          class="p-button-success"
          @click="moveTicket"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketService from "../../services/tickets.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
export default {
  name: "importTickes",
  props: ["showToast", "show", "ticketId", "ticketSubject"],
  watch: {},
  data() {
    return {
      getTicketId: null,
      searchTicketTerm: null,
      ticketsList: [],
      selectedTickets: [],
      errorMsg: null,
    };
  },
  created() {},
  methods: {
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    reset() {
      this.getTicketId = null;
      this.searchTicketTerm = null;
      this.ticketsList = [];
      this.selectedTickets = [];
      this.errorMsg = null;
    },
    moveTicket() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let message = `Tem a certeza que pretende importar os tickets ${this.selectedTickets.join(
          ", "
        )} para o Ticket W4M_${this.ticketId}?`;
        message += `\nEste processo é irreversível.`;
        this.$confirm.require({
          key: "dialogMoveTicket",
          header: "Mover Ticket",
          message: message,
          icon: "pi pi-question-circle",
          acceptLabel: "Sim",
          acceptIcon: "pi pi-check",
          acceptClass: "p-button-success p-button p-component",
          rejectLabel: "Não",
          rejectIcon: "pi pi-times",
          rejectClass: "p-button-danger p-button p-component",
          accept: () => {
            return this.importTickets();
          },
          reject: () => {},
        });
      });
    },
    importTickets() {
      return ticketService
        .importTickets(this.ticketId, { tickets_import: this.selectedTickets })
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao mover ticket",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("importedTicket", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Ticket movido com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("importedTicket", {
            status: "ok",
            destinationTickedId: response.id,
          });
        });
    },
    getTicketById() {
      this.searchTicketTerm = null;
      this.ticketsList = [];
      if (this.ticketId == this.getTicketId) {
        return (this.errorMsg =
          "O id do ticket de destino tem de ser diferente do id do ticket de origem");
      }
      if (this.getTicketId == null || this.getTicketId == "") {
        return (this.errorMsg = "Introduza um id válido");
      }
      this.errorMsg = null;
      return ticketService
        .getBasicInformation(this.getTicketId)
        .then((response) => {
          if (!response) {
            return (this.errorMsg = `O ticket com o id ${this.getTicketId} não existe`);
          }
          return (this.ticketsList = [response]);
        });
    },
    searchTicket() {
      this.getTicketId = null;
      this.ticketsList = [];
      if (this.searchTicketTerm == null || this.searchTicketTerm == "") {
        return (this.errorMsg = "Introduza um termo válido");
      }
      this.errorMsg = null;
      return ticketService.search(this.searchTicketTerm).then((response) => {
        if (!response || response.length == 0) {
          return (this.errorMsg = `Não existem tickets com termo ${this.searchTicketTerm}`);
        }
        return (this.ticketsList = response);
      });
    },
    getEmailInfo(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    dateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    viewTicket(ticketId) {
      let route = this.$router.resolve({
        path: `/helpdesk-tickets-detail/${ticketId}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
