<template>
  <div>
    <Loading :active.sync="loading" :isFullPage="true"></Loading>
    <div
      class="p-grid p-col-12 p-lg-12"
      v-if="ticketInfo"
      :style="{ position: 'relative' }"
    >
      <Dialog
        :visible.sync="emailCompose.show"
        :containerStyle="{ width: $isMobile() ? '100vw' : '40vw' }"
        :position="'bottomright'"
        :maximizable="true"
        v-on:hide="cancelSend"
        :header="emailCompose.header"
      >
        <div class="p-col-12">
          <div class="p-col-12 p-d-flex p-ai-center">
            <div class="p-col-1">
              <b>Para</b>
            </div>
            <div class="p-col-11">
              <Chips
                :allowDuplicate="false"
                style="width: 100%"
                v-bind:class="[
                  {
                    'p-invalid': emailCompose.response.errorTo,
                  },
                  'p-ml-1',
                ]"
                separator=","
                v-model="emailCompose.response.to"
              >
                <template #chip="slotProps">
                  <div class="p-d-flex p-ai-center">
                    <i class="pi pi-user p-mr-1 circle"></i>
                    <span>{{ slotProps.value }}</span>
                  </div>
                </template>
              </Chips>
              <small
                v-if="emailCompose.response.errorTo"
                class="p-error"
                role="alert"
              >
                Destinatário está vazio
              </small>
            </div>
          </div>
          <div class="p-col-12 p-d-flex p-ai-center">
            <div class="p-col-1">
              <b>CC</b>
            </div>
            <div class="p-col-11">
              <Chips
                :allowDuplicate="false"
                style="width: 100%"
                separator=","
                v-model="emailCompose.response.cc"
              >
                <template #chip="slotProps">
                  <div class="p-d-flex p-ai-center">
                    <i class="pi pi-user p-mr-1 circle"></i>
                    <span>{{ slotProps.value }}</span>
                  </div>
                </template>
              </Chips>
            </div>
          </div>
          <div class="p-col-12">
            <Editor
              name="body"
              v-model="emailCompose.response.content"
              editorStyle="height: 200px"
              v-bind:class="[
                { 'p-invalid': emailCompose.response.errorContent },
                'form-control',
              ]"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                </span>
              </template>
            </Editor>
            <small
              class="p-error"
              role="alert"
              v-if="emailCompose.response.errorContent"
            >
              O email está vazio
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <FileUpload
              :ref="'attachmentInput'"
              :name="'attachments[]'"
              chooseLabel="Adicionar Anexo"
              :multiple="true"
              :showUploadButton="false"
              :showCancelButton="false"
              :maxFileSize="2000000000"
            />
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-5">
              <span class="p-float-label">
                <Dropdown
                  :name="'ticket-status'"
                  :options="status"
                  :filter="true"
                  :optionLabel="'name'"
                  :optionValue="'status'"
                  v-model="emailCompose.email.newstatus"
                  v-validate="'required'"
                  style="width: 100%"
                  v-bind:class="[
                    { 'p-invalid': emailCompose.response.errorStatus },
                    'form-control',
                  ]"
                >
                </Dropdown>
                <label :for="'ticket-status'"
                  >Selecionar Estado do Ticket</label
                >
              </span>
              <small
                v-if="emailCompose.response.errorStatus"
                class="p-error"
                role="alert"
              >
                Novo Estado é obrigatório
              </small>
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            :label="'Enviar'"
            icon="pi pi-send"
            class="p-button-sm p-button-success"
            @click="sendEmail()"
          />
          <Button
            :label="'Cancelar'"
            icon="pi pi-times"
            class="p-button-sm p-button-danger"
            @click="cancelSend()"
          />
        </template>
      </Dialog>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <ConfirmDialog />
      <ChangeResponsible
        :showToast="true"
        :show="setResposible"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        :currentTechnician="ticketInfo.assigned_to"
        v-on:cancel="setResposible = false"
        v-on:changeTechnicianResp="changeResposibleOk"
      />
      <ChangeCategory
        :showToast="true"
        :show="setCategory"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        :categoryId="ticketInfo.caetgory"
        v-on:cancel="setCategory = false"
        v-on:changedTicketCategory="changeCategoryOk"
      />
      <ChangePriority
        :showToast="true"
        :show="setPriority"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        :priorityId="ticketInfo.priority"
        v-on:cancel="setPriority = false"
        v-on:changedTicketPriority="changePriorityOk"
      />
      <ChangeStatus
        :showToast="true"
        :show="setStatus"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        :statusId="ticketInfo.status"
        v-on:cancel="setStatus = false"
        v-on:changedTicketStatus="changeStatusOk"
      />
      <ImportTicket
        :showToast="true"
        :show="importTicket"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        v-on:cancel="importTicket = false"
        v-on:importedTicket="importTicketOk"
      />
      <CategorizeTicket
        :showToast="true"
        :show="categorizeTicket"
        :ticketId="ticketInfo.id"
        :ticketSubject="ticketInfo.subject"
        v-on:cancel="categorizeTicket = false"
        v-on:categorizedTicket="categorizeTicketOk"
      />
      <AddNote
        :showToast="true"
        :show="addNote"
        :ticketId="ticketInfo.id"
        v-on:cancel="addNote = false"
        v-on:addedNote="noteAdded"
      />
      <div class="p-col-12 card p-shadow-3 p-d-flex p-ai-center">
        <h3>Ticket {{ ticketInfo.subject }}</h3>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="p-col-12 card p-shadow-3">
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Cliente</b></div>
            <div class="p-col-10">
              {{ getInfoFromEmail(ticketInfo.client_email) }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Email</b></div>
            <div class="p-col-10">{{ ticketInfo.client_email }}</div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Descrição</b></div>
            <div class="p-col-10">{{ ticketInfo.description }}</div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1" v-if="ticketInfo.created_at">
            <div class="p-col-2"><b>Criado</b></div>
            <div class="p-col-10">
              {{
                ticketInfo.created_by == 0
                  ? "Intranet"
                  : ticketInfo.ticket_created_user.username
              }}
              | {{ getDateTime(ticketInfo.created_at) }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1" v-if="ticketInfo.updated_at">
            <div class="p-col-2"><b>Atualizado</b></div>
            <div
              class="p-col-10"
              v-if="
                ticketInfo.updated_at &&
                ticketInfo.updated_at != ticketInfo.created_at
              "
            >
              {{
                ticketInfo.updated_by == 0
                  ? "Intranet"
                  : ticketInfo.ticket_updated_user.username
              }}
              | {{ getDateTime(ticketInfo.updated_at) }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2 p-d-flex p-jc-between">
              <b>Responsável</b
              ><i
                v-if="!ticketInfo.assigned_to"
                class="pi pi-exclamation-triangle p-ml-2 p-error"
              ></i>
            </div>
            <div
              v-bind:class="[
                { error: !ticketInfo.assigned_to },
                'p-col-10',
                'clickable-item',
              ]"
              @click="setResposible = true"
            >
              {{
                ticketInfo.assigned_to
                  ? ticketInfo.ticket_assigned_user.username
                  : "(Sem Responávél Associado)"
              }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Estado</b></div>
            <div class="p-col-10 clickable-item" @click="setStatus = true">
              {{ ticketInfo.ticket_status.name }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Prioridade</b></div>
            <div class="p-col-10 clickable-item" @click="setPriority = true">
              {{ ticketInfo.ticket_priority.name }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2 p-d-flex p-jc-between">
              <b>Categoria</b
              ><i
                v-if="!ticketInfo.category"
                class="pi pi-exclamation-triangle p-error"
              ></i>
            </div>
            <div
              v-bind:class="[
                { error: !ticketInfo.assigned_to },
                'p-col-10',
                'clickable-item',
              ]"
              @click="setCategory = true"
            >
              {{
                ticketInfo.category
                  ? ticketInfo.ticket_category.name
                  : "(Sem Categoria Associada)"
              }}
            </div>
          </div>
          <divider />
          <Button
            v-tooltip="'Importar outros tickets para este'"
            label="Importar Tickets"
            icon="pi pi-directions-alt"
            class="p-button-warning"
            @click="importTicket = true"
          />
        </div>
        <div class="p-col-12 card p-shadow-3">
          <h4>Classificação</h4>
          <div v-if="ticketInfo.contract_id">
            <h5>Informação</h5>
            <div><b>Cliente: </b> {{ ticketInfo.contract.client }}</div>
            <div><b>Entidade: </b> {{ ticketInfo.contract.entity_name }}</div>
            <h5>Contrato</h5>
            <div><b>Produto: </b> {{ ticketInfo.contract.product }}</div>
            <div><b>Descrição: </b> {{ ticketInfo.contract.description }}</div>
            <div><b>Designação: </b> {{ ticketInfo.contract.designation }}</div>
            <div v-bind:class="[{ error: ticketInfo.contract.inactive == 1 }]">
              <b>Estado: </b>
              {{ ticketInfo.contract.inactive == 0 ? "Ativo" : "Inativo" }}
            </div>
            <div><b>SLA: </b> {{ ticketInfo.contract.sla }}</div>
            <div><b>SLA Remoto: </b> {{ ticketInfo.contract.sla_remote }}</div>
            <div>
              <b>Tipo de Suporte: </b> {{ ticketInfo.contract.support_type }}
            </div>
            <div>
              <b>Periodo de Suporte: </b>
              {{ ticketInfo.contract.support_period }}
            </div>
            <div
              v-if="ticketInfo.equipments && ticketInfo.equipments.length > 0"
              class="p-mt-3"
            >
              <h5>Equipamentos</h5>
              <div v-for="equip in ticketInfo.equipments" :key="equip.id">
                <divider />
                <div class="p-mt-2">
                  <b>Tipo: </b>
                  {{ equip.type }}
                </div>
                <div>
                  <b>Estado: </b>
                  {{ equip.status }}
                </div>
                <div>
                  <b>Licença: </b>
                  {{ equip.license }}
                </div>
                <div>
                  <b>Série: </b>
                  {{ equip.serie }}
                </div>
                <div class="p-d-flex p-ai-center">
                  <b>Link: </b>
                  <Button
                    :label="equip.link"
                    class="p-button-text p-button-plain p-button-sm"
                    @click="openlink(equip.link)"
                  />
                </div>
                <div>
                  <b>Observações: </b>
                  {{ equip.observation }}
                </div>
              </div>
            </div>
          </div>
          <divider />
          <Button
            label="Classificar Ticket"
            icon="pi pi-briefcase"
            class="p-button-warning"
            @click="categorizeTicket = true"
          />
        </div>
        <div class="p-col-12">
          <Accordion :activeIndex="activeEmailIndex">
            <AccordionTab
              class="accordion-custom"
              v-for="email in ticketInfo.ticket_messages"
              :key="email.id"
            >
              <template #header>
                <div
                  class="p-d-flex p-jc-between"
                  style="width: 100%"
                  @click="tabOpen(email)"
                >
                  <div>
                    <b>{{ email.email_from }}</b>
                  </div>
                  <div>
                    <i
                      class="pi pi-eye-slash p-mr-1"
                      v-tooltip="'Não Lido'"
                      v-if="email.status == 1"
                    ></i>
                    <i
                      class="pi pi-eye p-mr-1"
                      v-tooltip="'Lido'"
                      v-if="email.status == 2"
                    ></i>
                    <i
                      class="pi pi-send p-mr-1"
                      v-tooltip="'Enviado'"
                      v-if="email.status == 3"
                    ></i>
                    <i
                      class="pi pi-paperclip p-mr-1"
                      v-tooltip="'Anexos'"
                      v-if="email.message_attachments.length > 0"
                    ></i>
                    {{ email.received_on }}
                  </div>
                </div>
              </template>
              <div>
                <div class="card p-shadow-3 p-grid" v-if="email.status < 4">
                  <div class="p-col-10">
                    <div>
                      <b>De:</b> {{ email.email_from }} |
                      {{ email.received_on }}
                    </div>
                    <div class="p-mt-1"><b>Para:</b> {{ email.email_to }}</div>
                    <div class="p-mt-1" v-if="email.email_cc != ''">
                      <b>CC:</b> {{ email.email_cc }}
                    </div>
                  </div>
                  <div class="p-col-2 p-d-flex p-ai-end p-flex-column">
                    <div>
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger"
                        v-tooltip="'Eliminar Email'"
                        @click="removeMessage(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text"
                        v-tooltip="'Reencaminhar'"
                        @click="forwardEmail(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text invert"
                        v-tooltip="'Responder'"
                        @click="responseEmail(email)"
                      />
                    </div>
                  </div>
                  <div class="p-col-12">
                    <divider />
                    <div
                      v-if="email.body_html != ''"
                      v-html="email.html"
                      style="overflow-x: auto"
                    ></div>
                    <pre v-else style="overflow-x: auto">{{ email.body }}</pre>
                    <div v-if="email.message_attachments.length > 0">
                      <divider />
                      <i class="pi pi-paperclip"></i><b>Anexos:</b>
                      <div>
                        <Button
                          class="p-button-sm p-button-rounded p-ml-1 p-mt-1"
                          v-for="attachment in email.message_attachments"
                          :key="attachment.id"
                          :label="attachment.name"
                          icon="pi pi-file"
                          @click="downloadAttachments(email.id, attachment.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="p-col-12 card p-shadow-3">
          <Accordion :multiple="true">
            <AccordionTab class="accordion-custom">
              <template #header>
                <div style="font-size: large">
                  <i class="pi pi-comments p-mr-2"></i>Notas
                </div>
              </template>
              <Button
                label="Adicionar Nota"
                icon="pi pi-plus"
                class="p-button-success"
                @click="addNote = true"
              />
              <divider />
              <PrimeVueTimeline
                :value="ticketInfo.ticket_notes"
                align="left"
                class="customized-timeline"
              >
                <template #marker>
                  <span class="custom-marker p-shadow-4 p-mt-3">
                    <i :class="'pi pi-comment'"></i>
                  </span>
                </template>
                <template #content="slotProps">
                  <Card class="p-shadow-4 p-mt-2">
                    <template #title>
                      {{ slotProps.item.user_created.username }}
                    </template>
                    <template #subtitle>
                      {{ getDateTime(slotProps.item.created_at) }}
                    </template>
                    <template #content>
                      <div v-html="slotProps.item.note"></div>
                    </template>
                  </Card>
                </template>
              </PrimeVueTimeline>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ticketsService from "../services/tickets.service";
import messagesService from "../services/ticketsEmails.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import ChangeResponsible from "../components/helpdeskTickets/ChangeResponsible.vue";
import ChangeCategory from "../components/helpdeskTickets/ChangeCategory.vue";
import ChangePriority from "../components/helpdeskTickets/ChangePriority.vue";
import ChangeStatus from "../components/helpdeskTickets/ChangeStatus.vue";
import ImportTicket from "../components/helpdeskTickets/ImportTicket.vue";
import CategorizeTicket from "../components/helpdeskTickets/Categorize.vue";
import AddNote from "../components/helpdeskTickets/AddNote.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PrimeVueTimeline from "primevue/timeline";
export default {
  name: "TicketDetail",
  components: {
    PrimeVueTimeline,
    ChangeResponsible,
    ChangeCategory,
    ChangePriority,
    ChangeStatus,
    ImportTicket,
    CategorizeTicket,
    AddNote,
    Loading,
  },
  data() {
    return {
      loading: true,
      ticketInfo: null,
      setResposible: false,
      setCategory: false,
      setPriority: false,
      setStatus: false,
      importTicket: false,
      addNote: false,
      categorizeTicket: false,
      activeEmailIndex: 0,
      status: [],
      emailCompose: {
        show: false,
        header: null,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    this.ticketInfo = await this.getTicketInfo();
    if (!this.ticketInfo.ticket_messages) {
      this.ticketInfo.ticket_messages = [];
    }
    this.status = await this.getStatus();
    this.loading = false;
  },
  async created() {},
  methods: {
    getStatus() {
      return ticketsService.getStatusList().then((response) => {
        let status = response.filter(
          (state) => state.status >= 1 && state.status <= 5
        );
        return status;
      });
    },
    getTicketInfo() {
      const processedHtml = (email) => {
        let el = new DOMParser().parseFromString(email.body_html, "text/html");
        [...email.message_attachments_inline].forEach((inlineImg) => {
          let image;
          if (inlineImg.content_id) {
            image = el.querySelector(`img[src$='${inlineImg.content_id}' i]`);
          } else {
            image = el.querySelector(`img[alt$='${inlineImg.name}']`);
          }
          if (!image) {
            email.message_attachments.push({
              id: inlineImg.id,
              name: inlineImg.name,
            });
          } else {
            image.src = `data:${inlineImg.mimetype};${inlineImg.encoding},${inlineImg.data}`;
          }
        });
        return el.documentElement.innerHTML;
      };
      return ticketsService
        .getTicketInfo(this.$route.params.ticketId)
        .then((response) => {
          response.ticket_messages = response.ticket_messages.map((v) => ({
            ...v,
            showCompose: false,
            newstatus: null,
            response: {
              to: [],
              cc: [],
              content: "",
              errorStatus: false,
              errorContent: false,
              errorTo: false,
            },
            html: processedHtml(v),
          }));
          return response;
        });
    },
    getInfoFromEmail(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    getDateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    changeResposibleOk(response) {
      if (response.status == "ok") {
        this.ticketInfo.assigned_to = response.result.technician_id;
        this.ticketInfo.ticket_assigned_user = {
          userno: response.result.responsible_user_id,
          username: response.result.responsible_user,
          tecnico: response.result.technician_id,
        };
      }
      return (this.setResposible = false);
    },
    changeCategoryOk(response) {
      if (response.status == "ok") {
        this.ticketInfo.category = response.result.category_id;
        this.ticketInfo.ticket_category = {
          id: response.result.category_id,
          name: response.result.category_name,
        };
      }
      return (this.setCategory = false);
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        this.ticketInfo.priority = response.result.priority_id;
        this.ticketInfo.ticket_priority = {
          priority: response.result.priority_id,
          name: response.result.priority_name,
        };
      }
      return (this.setPriority = false);
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        this.ticketInfo.status = response.result.status_id;
        this.ticketInfo.ticket_status = {
          status: response.result.status_id,
          name: response.result.status_name,
        };
      }
      return (this.setStatus = false);
    },
    setMessageStatus(email, status) {
      return messagesService
        .setStatus(email.id, { status_id: status })
        .then((response) => {
          email.status = response.status_id;
          email.message_status.status = response.status_id;
          email.message_status.name = response.status_name;
        });
    },
    removeMessage(email) {
      this.$confirm.require({
        header: "Eliminar Email",
        message: "Tem a certeza que deseja eliminar este email?",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        accept: () => {
          return this.setMessageStatus(email, 4);
        },
        reject: () => {},
      });
    },
    downloadAttachments(emailId, attachmentId) {
      return messagesService
        .getAttachment(emailId, attachmentId)
        .then((response) => {
          let linkSource = `data:${response.mimetype};base64,${response.data}`;
          let downloadLink = document.createElement("a");
          let fileName = response.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    tabOpen(email) {
      if (email.status == 1) {
        return this.setMessageStatus(email, 2);
      }
    },
    forwardEmail(email) {
      email.newstatus = null;
      return (this.emailCompose = {
        show: true,
        header: "Reencaminhar Email",
        email: email,
        response: {
          to: [],
          cc: ["suporte@w4msolutions.com"],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    responseEmail(email) {
      let cc = [];
      if (email.email_cc != "''") {
        let ccAux = email.email_cc.replace(/\s/g, "");
        if (ccAux != "") {
          cc = ccAux.split(",");
        }
      }
      if (!email.email_cc.includes("suporte@w4msolutions.com")) {
        cc.push("suporte@w4msolutions.com");
      }
      email.newstatus = null;
      return (this.emailCompose = {
        show: true,
        header: "Responder a Email",
        email: email,
        response: {
          to: email.email_from.split(", "),
          cc: cc,
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    cancelSend() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.emailCompose = {
        show: false,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      });
    },
    sendEmail() {
      let hasError = false;
      if (this.emailCompose.response.content == "") {
        hasError = true;
        this.emailCompose.response.errorContent = true;
      } else {
        this.emailCompose.response.errorContent = false;
      }
      if (!this.emailCompose.email.newstatus) {
        hasError = true;
        this.emailCompose.response.errorStatus = true;
      } else {
        this.emailCompose.response.errorStatus = false;
      }
      if (this.emailCompose.response["to"].length == 0) {
        hasError = true;
        this.emailCompose.response.errorTo = true;
      } else {
        this.emailCompose.response.errorTo = false;
      }
      if (hasError) {
        return;
      }

      var formData = new FormData();
      formData.append("email_id", this.emailCompose.email.id);
      formData.append("new_state", this.emailCompose.email.newstatus);
      formData.append("to", this.emailCompose.response["to"]);
      formData.append("cc", this.emailCompose.response.cc);
      formData.append("body", this.emailCompose.response.content);
      let attachments = this.$refs["attachmentInput"];

      if (attachments.files.length > 0) {
        attachments.files.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }
      this.$validator.pause();
      this.$validator.reset();
      this.loading = true;
      return ticketsService
        .sendMessage(this.ticketInfo.id, formData)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Ocorreu um erro ao enviar o email",
              detail: "",
              life: 3000,
            });
          }
          this.ticketInfo.status = response.status_id;
          this.ticketInfo.ticket_status = {
            status: response.status_id,
            name: response.status_name,
          };
          this.$toast.add({
            severity: "success",
            summary: `Email enviado com sucesso`,
            detail: ``,
            life: 3000,
          });
          return this.cancelSend();
        });
    },
    openlink(link) {
      window.open(link, "_blank");
    },
    importTicketOk(response) {
      if (response.status == "ok") {
        let route = this.$router.resolve({
          path: `/helpdesk-tickets-detail/${response.destinationTickedId}`,
        });
        window.open(route.href, "_self");
      }
    },
    categorizeTicketOk(response) {
      if (response.status == "ok") {
        this.ticketInfo.contract_id = response.data.contract.id;
        this.ticketInfo.contract = response.data.contract;
        this.ticketInfo.equipments = response.data.equipments;
      }
      return (this.categorizeTicket = false);
    },
    noteAdded(response) {
      if (response.status == "ok") {
        this.ticketInfo.ticket_notes.unshift(response.note);
      }
      return (this.addNote = false);
    },
  },
};
</script>
<style>
.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}
.error {
  color: #dc3545 !important;
}
.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}

.invert {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.p-multiselect-label {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
.p-timeline-event-opposite {
  display: none !important;
}
.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.p-chips-token {
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}
</style>
