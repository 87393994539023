<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogMoveTicket" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '80vw' }"
      :header="'Classificar Ticket'"
      :modal="true"
      :closable="false"
    >
      <p>
        <b>Ticket {{ ticketSubject }}</b>
      </p>
      <div class="p-fluid p-formgrid p-grid" :style="{ position: 'relative' }">
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <div class="p-col-5 p-md-3 p-mt-6">
          <span class="p-float-label">
            <InputText
              name="equipamentsSearchInput"
              v-model="searchEquipamentsTerm"
              :min="1"
              v-bind:class="['form-control']"
              @keyup.enter="searchEquipaments"
            >
            </InputText>
            <label for="equipamentsSearchInput">Pesquisar Equipamentos</label>
          </span>
        </div>
        <div class="p-field p-col-1 p-md-1 p-mt-6">
          <Button
            icon="pi pi-search"
            class="p-button-rounded"
            @click="searchEquipaments"
          />
        </div>
        <div class="p-col-5 p-md-3 p-mt-6">
          <span class="p-float-label">
            <InputText
              name="contractsSearchInput"
              v-model="searchContractsTerm"
              :min="1"
              v-bind:class="['form-control']"
              @keyup.enter="searchContracts"
            >
            </InputText>
            <label for="contractsSearchInput">Pesquisar Contratos</label>
          </span>
        </div>
        <div class="p-field p-col-1 p-md-1 p-mt-6">
          <Button
            icon="pi pi-search"
            class="p-button-rounded"
            @click="searchContracts"
          />
        </div>

        <div class="p-field p-col-12 p-md-12 p-error" v-if="errorMsg">
          {{ errorMsg }}
        </div>
      </div>
      <div v-if="equipamentsList.length > 0">
        <h4>Equipamentos</h4>
        <DataTable
          :value="equipamentsList"
          :selection.sync="selectedEquipaments"
          responsiveLayout="scroll"
          dataKey="id"
          :filters.sync="filterEquipaments"
          filterDisplay="row"
          sortField="uni_project"
          @row-select="selectedRow"
          @row-unselect="unselectRow"
        >
          <Column selectionMode="multiple" :headerStyle="{ width: '3em' }" />
          <Column sortable field="client" header="Cliente" filterField="client">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="uni_client"
            header="Entidade"
            filterField="uni_client"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="uni_project"
            header="Projeto"
            filterField="uni_project"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="equipment_name"
            header="Equipamento"
            filterField="equipment_name"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column sortable field="status" header="Estado" filterField="status">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column sortable field="type" header="Tipo" filterField="type">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="license"
            header="Licença"
            filterField="license"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="observation"
            header="Observações"
            filterField="observation"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <div v-if="contractsList.length > 0">
        <h4>Equipamentos</h4>
        <DataTable
          :value="contractsList"
          :selection.sync="contractsSelect"
          responsiveLayout="scroll"
          dataKey="id"
          :filters.sync="filterContracts"
          filterDisplay="row"
          sortField="uni_project"
        >
          <Column selectionMode="single" :headerStyle="{ width: '3em' }" />
          <Column
            sortable
            field="client_name"
            header="Cliente"
            filterField="client_name"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="entity_name"
            header="Entidade"
            filterField="entity_name"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="description"
            header="Descrição"
            filterField="description"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="designation"
            header="Designação"
            filterField="designation"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="product"
            header="Produto"
            filterField="product"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="inactive"
            header="Ativo"
            filterField="inactive"
          >
            <template #body="slotProps">
              {{ slotProps.data.inactive == "1" ? "Não" : "Sim" }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @input="filterCallback()"
                :options="[
                  { value: '0', label: 'Sim' },
                  { value: '1', label: 'Não' },
                ]"
                optionValue="value"
                optionLabel="label"
                class="p-column-filter"
                :showClear="true"
                :style="{
                  width: '80%',
                }"
              >
              </Dropdown>
            </template>
          </Column>
          <Column header="Tipo de Suporte">
            <template #body="slotProps">
              {{ slotProps.data.support_type }} /
              {{ slotProps.data.support_period }}
            </template>
          </Column>
          <Column header="SLA">
            <template #body="slotProps">
              <b>SLA: </b>{{ slotProps.data.sla }} <br />
              <b>SLA Remoto: </b>{{ slotProps.data.sla_remote }}
            </template>
          </Column>
        </DataTable>
      </div>
      <template #footer>
        <Button
          v-if="contractsSelect || selectedEquipaments.length > 0"
          :label="'Classificar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="categorizeTicket"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketService from "../../services/tickets.service";
import contractsService from "../../services/contracts.services";
import { FilterMatchMode } from "primevue/api/";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CategorizeTicket",
  components: {
    Loading,
  },
  props: ["showToast", "show", "ticketId", "ticketSubject"],
  watch: {},
  data() {
    return {
      searchEquipamentsTerm: null,
      equipamentsList: [],
      selectedEquipaments: [],
      searchContractsTerm: null,
      contractsList: [],
      contractsSelect: null,
      errorMsg: null,
      showLoader: false,
      filterEquipaments: {
        client: { value: null, matchMode: FilterMatchMode.CONTAINS },
        uni_project: { value: null, matchMode: FilterMatchMode.CONTAINS },
        uni_client: { value: null, matchMode: FilterMatchMode.CONTAINS },
        equipment_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        license: { value: null, matchMode: FilterMatchMode.CONTAINS },
        observation: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterContracts: {
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        entity_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        designation: { value: null, matchMode: FilterMatchMode.CONTAINS },
        product: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inactive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  created() {},
  methods: {
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    reset() {
      this.searchEquipamentsTerm = null;
      this.equipamentsList = [];
      this.selectedEquipaments = [];
      this.searchContractsTerm = null;
      this.contractsList = [];
      this.contractsSelect = null;
      this.errorMsg = null;
    },
    categorizeTicket() {
      let params = {
        contract_id: null,
        equipments: [],
      };
      let hasError = false;
      if (
        this.selectedEquipaments.length > 0 &&
        this.selectedEquipaments[0].contract != null
      ) {
        params.contract_id = this.selectedEquipaments[0].contract.id;
        this.selectedEquipaments.forEach((equipament) => {
          if (equipament.contract == null) {
            hasError = true;
            return this.$toast.add({
              severity: "error",
              summary: "Um dos equipamentos não tem contrato associado",
              detail: "",
              life: 5000,
            });
          }

          if (equipament.contract.id != params.contract_id) {
            hasError = true;
            return this.$toast.add({
              severity: "error",
              summary:
                "Os equipamentos seletionados não pertence todos ao mesmo contrato",
              detail: "",
              life: 5000,
            });
          }
          params.equipments.push(equipament.id);
        });
      } else if (this.contractsSelect && this.contractsSelect.id != undefined) {
        params.contract_id = this.contractsSelect.id;
      }
      if (params.contract_id == null) {
        hasError = true;
        return this.$toast.add({
          severity: "error",
          summary: "Não existem equipamentos nem contratos selecionados",
          detail: "",
          life: 5000,
        });
      }
      if (hasError) {
        return;
      }
      this.showLoader = true;
      return ticketService
        .setContract(this.ticketId, params)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro classificar o ticket",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("categorizedTicket", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Ticket classificado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          this.reset();
          this.showLoader = false;
          return this.$emit("categorizedTicket", {
            status: "ok",
            data: response,
          });
        });
    },
    searchEquipaments() {
      this.searchContractsTerm = null;
      this.contractsList = [];
      this.contractsSelect = null;
      this.equipamentsList = [];
      this.selectedEquipaments = [];
      if (
        this.searchEquipamentsTerm == null ||
        this.searchEquipamentsTerm == ""
      ) {
        return (this.errorMsg = "Introduza um termo válido");
      }
      this.errorMsg = null;
      this.showLoader = true;
      return contractsService
        .searchEquipments(this.searchEquipamentsTerm)
        .then((response) => {
          this.showLoader = false;
          if (!response || response.length == 0) {
            return (this.errorMsg = `Não existem equipamentos com termo ${this.searchEquipamentsTerm}`);
          }
          return (this.equipamentsList = response);
        });
    },
    searchContracts() {
      this.searchEquipamentsTerm = null;
      this.contractsList = [];
      this.contractsSelect = null;
      this.equipamentsList = [];
      this.selectedEquipaments = [];
      if (this.searchContractsTerm == null || this.searchContractsTerm == "") {
        return (this.errorMsg = "Introduza um termo válido");
      }
      this.errorMsg = null;
      this.showLoader = true;
      return contractsService
        .searchContracts(this.searchContractsTerm)
        .then((response) => {
          this.showLoader = false;
          if (!response || response.length == 0) {
            return (this.errorMsg = `Não existem contratos com termo ${this.searchEquipamentsTerm}`);
          }
          return (this.contractsList = response);
        });
    },
    selectedRow(event) {
      if (event.type != "checkbox") {
        return;
      }
      this.filterEquipaments.client.value = event.data.client;
      this.filterEquipaments.uni_project.value = event.data.uni_project;
      this.filterEquipaments.uni_client.value = event.data.uni_client;
      return;
    },
    unselectRow(event) {
      if (event.type != "checkbox") {
        return;
      }
      if (this.selectedEquipaments.length == 0) {
        this.filterEquipaments.client.value = null;
        this.filterEquipaments.uni_project.value = null;
        this.filterEquipaments.uni_client.value = null;
      }
      return;
    },
  },
};
</script>
